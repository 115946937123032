.header {
  background-color: #0070BF;
}
.video{
  margin-left: 30%;
}
/* Media query for devices with a max width of 768px (tablets and mobile devices) */
@media (max-width: 768px) {
  .header {
    padding: 20px;
    text-align: center;
  }

  .video {
    margin: 0;
    width: 100%;
    display: block;
    margin-left:5%;
    box-sizing: border-box;
  }
}
