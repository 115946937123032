.exit-card {
    background-color: white;
    border-radius: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px auto;
    margin-top: 9%;
    height: 398px; /* Fixed height for larger screens */
    width: 1300px; /* Fixed width for larger screens */
  }
  
  .para {
    text-align: center;
    font-size: 60px;
  }
  
  .thank {
    text-align: center;
  }
  
  .button1 {
    background-color: #143D64;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin-left: 42%; /* Adjusted margin for larger screens */
    margin-top: 3%; /* Adjusted margin for larger screens */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  /* Media query for tablet devices */
  @media (max-width: 992px) {
    .exit-card {
      height: auto; /* Auto height for tablets */
      width: 90%; /* Full width for tablets */
     /* Adjusted margin-top for tablets */
    }
  
    .button1 {
      margin-left: 35%; /* Adjusted margin for tablets */
    }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 576px) {
    .exit-card {
      height: auto; /* Auto height for mobile */
      width: 90%; /* Full width for mobile */
      margin-top: 40%; /* Adjusted margin-top for mobile */
      padding: 15px; /* Adjusted padding for mobile */
      border-radius: 30px; /* Adjusted border-radius for mobile */
    }
  
    .para {
      font-size: 40px; /* Adjusted font size for mobile */
    }
  
    .button1 {
      margin-left: 30%; /* Adjusted margin-left for mobile */
      margin-top: 5%; /* Adjusted margin-top for mobile */
      font-size: 14px; /* Adjusted font size for mobile */
      padding: 12px 24px; /* Adjusted padding for mobile */
    }
  }
  